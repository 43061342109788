import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import Sidebar from '../components/Sidebar';
import WorkArea from '../components/WorkArea';
import '../css/KnowledgeBasePage.css';
import config from '../config';
import { Helmet } from 'react-helmet';

const KnowledgeBasePage = () => {
  const { application_id, knowledge_base_id } = useParams();
  const navigate = useNavigate();
  const [knowledgeBaseName, setKnowledgeBaseName] = useState('Загрузка...');
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchKnowledgeBaseInfo = async () => {
      const accessToken = localStorage.getItem('access_token');
      if (!accessToken) {
        setError('Токен доступа отсутствует. Пожалуйста, войдите в систему.');
        return;
      }

      try {
        const response = await axios.get(
          `${config.apiUrl}/knowledge_bases/${knowledge_base_id}`,
          {
            headers: {
              'accept': 'application/json',
              'Authorization': `Bearer ${accessToken}`,
            },
          }
        );
        setKnowledgeBaseName(response.data.name);
      } catch (err) {
        console.error('Ошибка при загрузке информации о базе знаний:', err);
        setError('Не удалось загрузить информацию о базе знаний');
        if (err.response && err.response.status === 404) {
          navigate(`/application/${application_id}`);
        }
      }
    };

    fetchKnowledgeBaseInfo();
  }, [knowledge_base_id, application_id, navigate]);

  const handleBack = () => {
    navigate(`/application/${application_id}`);
  };

  if (error) {
    return <div className="error-message">{error}</div>;
  }

  return (
    <div className="knowledge-base-page">
      <Helmet>
        <title>БО Интегратор - База знаний</title>
      </Helmet>
      <Sidebar 
        showBackButton={true}
        onBack={handleBack}
        knowledgeBaseName={knowledgeBaseName}
      />
      <div className="knowledge-base-content">
        <WorkArea 
          activeContent="file-list"
          applicationId={application_id}
          knowledgeBaseId={knowledge_base_id}
          knowledgeBaseName={knowledgeBaseName}
        />
      </div>
    </div>
  );
};

export default KnowledgeBasePage;