// MainPage.jsx

import React, { useState, useEffect } from 'react';
import '../css/MainPage.css'; // Подключение стилей
import logo from '../static/img/logo.svg';
import mainHeaderImg from '../static/img/main-header-img.png'
import mainBottomImg from '../static/img/main-bottom-img.png'
import product_img_1 from '../static/img/mainpage/product-1.png'
import product_img_2 from '../static/img/mainpage/product-2.png'
import product_img_3 from '../static/img/mainpage/product-3.png'
import { Helmet } from 'react-helmet';
import { useSwipeable } from 'react-swipeable';
import { useNavigate } from 'react-router-dom';
import config from '../config'
import Rates from './Rates';
import LawInfo from './LawInfo';

const MainPage = () => {

  return (
    <div className="main-page">
      <Helmet>
        <title>БО Интегратор - Ваш ассистент</title>
      </Helmet>
      <Navbar />
      <Header />
      <Products />
      <Rates />
      <About />
      <Promotion />
      <Footer />
    </div>
  );
};

const Navbar = () => {
  const navigate = useNavigate();

  const handleMainClick = () => {
    navigate('/');
  }
  const scrollToElement = (id) => {
    const element = document.getElementById(id);
    if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
      <nav className="navbar-main">
          <div className="nav-links">
          <img src={logo} alt="Логотип" className="main-logo-img" onClick={handleMainClick}/>

              <a href="/account">Аккаунт</a>
              <a href="#" onClick={() => scrollToElement('about')}>О нас</a>
              <a href="#" onClick={() => scrollToElement('rates')}>Тарифы</a>
          </div>
      </nav>
  );
};


  const Header = () => {
    const navigate = useNavigate();

    const handleLoginClick = () => {
        navigate('/auth?mode=login');
    };

    const handleRegisterClick = () => {
        navigate('/auth?mode=register');
    };
    return (
        <div>
          <div>
            <h1 className='main-header'>Ассистент Вашего бизнеса</h1>
          </div>
          <header className="header">
            <div className="left-column">
                <img src={mainHeaderImg} alt="Logo" />
            </div>
            <div className="right-column">
                <div className="button-container-main button-container">
                    <button className="main-login-button" onClick={handleLoginClick}>
                    <span className="main-login-button-text">Вход</span>
                    </button>
                    <button className="main-reg-button" onClick={handleRegisterClick}>
                    <span className="main-reg-button-text">Регистрация</span>
                    </button>
                </div>
            </div>
          </header>
        </div>
        
    );
};


const Products = () => {
  const pages = [
    {
      title: 'БАЗА ЗНАНИЙ',
      description: 'Описание продукта 1',
      additionalTitle: 'Дополнительный заголовок 1',
      additionalDescription: 'Дополнительное описание продукта 1',
      image: product_img_1,
    },
    {
      title: 'ЧАТ С АССИСТЕНТОМ',
      description: '"But I must explain to you how all this mistaken idea of denouncing pleasure and praising pain was born and I will give you a complete account of the system, and expound the actual teachings of the great explorer of the truth, the master-builder of human happiness. No one rejects, dislikes, or avoids pleasure itself, because it is pleasure, but because those who do not know how to pursue pleasure rationally encounter consequences that are extremely painful. Nor again is there anyone who loves or pursues or desires to obtain pain of itself, because it is pain, but because occasionally circumstances occur in which toil and pain can procure him some great pleasure. To take a trivial example, which of us ever undertakes laborious physical exercise, except to obtain some advantage from it? But who has any right to find fault with a man who chooses to enjoy a pleasure that has no annoying consequences, or one who avoids a pain that produces no resultant pleasure?"',
      additionalTitle: 'Дополнительный заголовок 2',
      additionalDescription: 'Дополнительное описание продукта 2',
      image: product_img_2,
    },
    {
      title: 'ИНТЕГРАЦИЯ',
      description: 'Описание продукта 3',
      additionalTitle: 'Дополнительный заголовок 3',
      additionalDescription: 'Дополнительное описание продукта 3',
      image: product_img_3,
    },
  ];

  const [currentPage, setCurrentPage] = useState(0);

  const handleDotClick = (index) => {
    setCurrentPage(index);
  };

  const swipeHandlers = useSwipeable({
    onSwipedLeft: () => handlePageChange(1),
    onSwipedRight: () => handlePageChange(-1),
    preventDefaultTouchmoveEvent: true,
    trackMouse: true,
  });

  const handlePageChange = (direction) => {
    const newIndex = currentPage + direction;
    if (newIndex >= 0 && newIndex < pages.length) {
      setCurrentPage(newIndex);
    }
  };

  return (
    <section className="products" {...swipeHandlers}>
      {pages.length > 0 && (
        <div className="product-page">
          <div className="product-info">
            <h2>{pages[currentPage].title}</h2>
            <p>{pages[currentPage].description}</p>
            <h3>{pages[currentPage].additionalTitle}</h3>
            <p>{pages[currentPage].additionalDescription}</p>
          </div>
          <img src={pages[currentPage].image} alt={pages[currentPage].title} />
        </div>
      )}
      
      {/* Пагинация */}
      <div className="pagination">
        {pages.map((_, index) => (
          <span
            key={index}
            className={`dot ${currentPage === index ? 'active' : ''}`}
            onClick={() => handleDotClick(index)}
          ></span>
        ))}
      </div>
    </section>
  );
};


const About = () => {
  return (
    <section className="about" id='about'>
      <h1 className='section-header'>О нас</h1>
      <div className="stats">
        <span className="stat"><span className='stat-number'>15</span> лет работы в сфере</span>
        <span className="stat"><span className='stat-number'>10 000</span> довольных клиентов</span>
        <span className="stat"><span className='stat-number'>20+</span> специалистов в команде</span>
      </div>

      <div className="messages">
        <div className="message-main left">
          Прошли путь от хаоса к порядку в бухгалтерском бизнесе. И можем помочь в этом вам.
        </div>
        <div className="message-main right">
          Для нас клиенты - это коллеги. Поэтому мы стремимся выстроить командное взаимодействие с нашими клиентами, делиться своим опытом, знаниями и временем.
        </div>
        <div className="message-main left">
          Знаем этот бизнес изнутри, и поэтому можем лучше других понять ваши боли и проблемы.
        </div>
      </div>

      <p className='info-main-site'>
        Больше информации у нас на{' '}
        <a href="https://onlineug.ru/" target="_blank" rel="noopener noreferrer">
          сайте
        </a>.
      </p>
    </section>
  );
};
const Promotion = () => {
  const navigate = useNavigate();

  const handleLoginClick = () => {
      navigate('/auth?mode=login');
  };

  const handleRegisterClick = () => {
      navigate('/auth?mode=register');
  };
  return (
      <div>
        <header className="header">
          <div className="left-column">
          <div className="button-container-main button-container">
                  <button className="main-login-button" onClick={handleLoginClick}>
                  <span className="main-login-button-text">Вход</span>
                  </button>
                  <button className="main-reg-button" onClick={handleRegisterClick}>
                  <span className="main-reg-button-text">Регистрация</span>
                  </button>
              </div>
          </div>
          <div className="right-column">
          <img src={mainBottomImg} alt="Logo" />
              
          </div>
        </header>
      </div>
      
  );
};

const Footer = () => {
  const scrollToElement = (id) => {
    const element = document.getElementById(id);
    if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <footer className="footer">
      <div className="footer-content">
        <div className="footer-column logo-column">
          <a href="#" className="logo-link" onClick={() => scrollToElement('top')}>
            <img src={logo} alt="Логотип" className="footer-logo" />
          </a>
        </div>
        <div className="footer-column">
          <h4>Навигация</h4>
          <ul>
            <li><a href="https://onlineug.ru/" target="_blank" rel="noopener noreferrer">Наш сайт</a></li>
            <li><a href='/privacy' target="_blank" rel="noopener noreferrer">Политика конфиденциальности</a></li>
            <li><a href="/lawinfo" rel="noopener noreferrer">Правовая информация</a></li>
          </ul>
        </div>
        <div className="footer-column">
          <h4>Связь</h4>
          <ul>
            <li><a href="https://t.me/onlasbiz_bot" target="_blank" rel="noopener noreferrer">Наш бот</a></li>
            <li><a href="https://t.me/onlasbiz" target="_blank" rel="noopener noreferrer">Телеграм-канал</a></li>
            <li><a href="https://vk.com/onlasbiz" target="_blank" rel="noopener noreferrer">Группа ВК</a></li>
          </ul>
        </div>
        <div className="footer-column address-column">
          <h4>Контактная информация</h4>
          <p>г. Краснодар, Улица им. 40-летия Победы, 20/1, 3 этаж, офис 301</p>
          <p>8 800-222-8166</p>
        </div>
      </div>
    </footer>
  );
};

export default MainPage;