import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom'; // Импортируем useNavigate вместо useHistory
import config from '../config';
import '../css/Purchase.css'; // Подключение стилей

const Purchase = ({ request }) => {
    const [amounts, setAmounts] = useState([]); // Инициализируем как массив
    const [totalAmount, setTotalAmount] = useState(0);
    const navigate = useNavigate(); // Инициализируем navigate

    useEffect(() => {
        const fetchRates = async () => {
            const fetchedAmounts = []; // Используем массив

            for (const rate of request.rates) {
                try {
                    const response = await fetch(`${config.apiUrl}/rates?rate_id=${rate.rate_id}`, {
                        method: 'GET',
                        headers: {
                            'Accept': 'application/json',
                        },
                    });

                    if (!response.ok) {
                        if (response.status === 401) {
                            navigate('/auth'); // Редирект на /auth при 401
                        }
                        throw new Error('Сетевая ошибка');
                    }

                    const data = await response.json();
                    const amount = data.price * rate.quantity; // Предполагаем, что data.price содержит цену тарифа
                    fetchedAmounts.push({ 
                        rate_id: rate.rate_id, 
                        tokens: data.tokens, 
                        rate_name: data.name, 
                        quantity: rate.quantity, 
                        price: data.price, 
                        amount 
                    }); // Добавляем объект с rate_id и amount
                } catch (error) {
                    console.error('Ошибка при получении тарифа:', error);
                }
            }

            setAmounts(fetchedAmounts); // Устанавливаем массив объектов
            const total = fetchedAmounts.reduce((acc, curr) => acc + curr.amount, 0); // Считаем общую сумму
            setTotalAmount(total);
        };

        fetchRates();
    }, [request.rates, navigate]); // Добавляем navigate в зависимости

    const handlePayment = async () => {
        const token = localStorage.getItem('access_token'); // Получаем токен из local storage
        const paymentData = {
            rates: request.rates.map(rate => ({
                id: rate.rate_id,
                quantity: rate.quantity,
            })),
        };

        try {
            const response = await fetch(`${config.apiUrl}/payment/create`, {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(paymentData),
            });

            if (!response.ok) {
                if (response.status === 401) {
                    navigate('/auth'); // Редирект на /auth при 401
                }
                throw new Error('Ошибка при создании платежа');
            }

            const result = await response.json();
            window.location.href = result.paymentLink; // Перенаправляем на paymentLink
        } catch (error) {
            console.error('Ошибка при обработке платежа:', error);
        }
    };

    // Проверяем, есть ли ненулевые значения в quantities для активации кнопки
    const hasValidRates = request.rates.some(rate => rate.quantity > 0);

    return (
        <div className="purchase-summary">
            <h2>Покупка</h2>
            <ul>
                {amounts.map(({ rate_id, rate_name, tokens, quantity, price }) => (
                    <li key={rate_id}>
                        <p>Тариф {rate_name}: {quantity} X {price.toFixed(2)} ₽</p>
                        <p>Токенов: {parseInt(quantity) * parseInt(tokens)}</p>
                    </li>
                ))}
            </ul>
            <h3>Общая сумма: {totalAmount.toFixed(2)} ₽</h3>
            <button 
                className='submit-button' 
                onClick={handlePayment} 
                disabled={!hasValidRates} // Деактивируем кнопку если нет ненулевых значений
            >
                Оплатить
            </button>
        </div>
    );
};

export default Purchase;