// src/components/ChatHistory.jsx
import React from 'react';

const ChatHistory = () => {
  return (
    <div>
      <h2>История чата</h2>
      {/* Здесь будет логика для отображения истории чата */}
    </div>
  );
};

export default ChatHistory;