// src/components/UserInfo.jsx
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import '../css/UserInfo.css';
import config from '../config';
import { useNavigate } from 'react-router-dom';

const UserInfo = () => {
  const [isEditing, setIsEditing] = useState(false);
  const [userData, setUserData] = useState({
    name: '',
    lastname: '',
    patronymic: '',
    phone: '',
    email: '',
    organization: '',
    inn: ''
  });
  const [tempUserData, setTempUserData] = useState({...userData});
  const [isLoading, setIsLoading] = useState(true);
  const [errors, setErrors] = useState({});
  const navigate = useNavigate();

  useEffect(() => {
    const fetchUserData = async () => {
      const accessToken = localStorage.getItem('access_token');
      if (!accessToken) {
        console.error('Токен доступа отсутствует.');
        navigate('/auth');
        return;
      }

      try {
        const response = await axios.get(`${config.apiUrl}/users/me/`, {
          headers: {
            'Authorization': `Bearer ${accessToken}`
          }
        });
        setUserData(response.data);
        setTempUserData(response.data);
        setIsLoading(false);
      } catch (err) {
        console.error('Ошибка при загрузке данных пользователя:', err);
        setErrors({ general: 'Не удалось загрузить данные пользователя' });
        setIsLoading(false);
      }
    };

    fetchUserData();
  }, [navigate]);

  const formatPhoneNumber = (phone) => {
    const cleaned = ('' + phone).replace(/\D/g, '');
    const match = cleaned.match(/^(\d{1})(\d{3})(\d{3})(\d{2})(\d{2})$/);
    if (match) {
      return `+${match[1]} (${match[2]}) ${match[3]}-${match[4]}-${match[5]}`;
    }
    return phone;
  };

  const handleEdit = () => {
    setIsEditing(true);
    setTempUserData({...userData});
    setErrors({});
  };

  const handleSave = async () => {
    const accessToken = localStorage.getItem('access_token');
    if (!accessToken) {
      console.error('Токен доступа отсутствует.');
      navigate('/auth');
      return;
    }
  
    let newErrors = {};
    
    // Ensure inn is treated as a string
    let innValue = tempUserData.inn !== null && tempUserData.inn !== undefined ? String(tempUserData.inn).trim() : '';
    
    if (innValue !== '' && !/^\d+$/.test(innValue)) {
      newErrors.inn = 'ИНН должен содержать только цифры';
    }
  
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }
  
    const dataToSend = {
      ...tempUserData,
      inn: innValue === '' ? null : parseInt(innValue, 10),
      organization: tempUserData.organization.trim() || null
    };
  
    try {
      const response = await axios.post(`${config.apiUrl}/users/me/`, dataToSend, {
        headers: {
          'Authorization': `Bearer ${accessToken}`,
          'Content-Type': 'application/json'
        }
      });
      setUserData({...response.data});
      setIsEditing(false);
      setErrors({});
    } catch (err) {
      console.error('Ошибка при сохранении данных пользователя:', err);
      if (err.response && err.response.data) {
        setErrors(err.response.data);
      } else {
        setErrors({general: 'Произошла ошибка при сохранении данных'});
      }
    }
  };

  const handleCancel = () => {
    setIsEditing(false);
    setTempUserData({...userData});
    setErrors({});
  };

  const handleChange = (e) => {
    const { id, value } = e.target;
    setTempUserData(prev => ({...prev, [id]: value}));
    setErrors(prev => ({...prev, [id]: undefined}));
  };

  if (isLoading) return <div>Загрузка...</div>;

  return (
    <div className="user-info">
      <h2>Профиль пользователя</h2>
      {errors.general && <div className="error-message">{errors.general}</div>}
      <form className="user-info-form">
        <div className="form-row">
          <div className="form-group">
            <label htmlFor="lastname" className="label">Фамилия</label>
            <input 
              type="text" 
              id="lastname" 
              value={isEditing ? tempUserData.lastname : userData.lastname} 
              readOnly={!isEditing}
              onChange={handleChange}
            />
            {errors.lastname && <div className="error-message">{errors.lastname}</div>}
          </div>
          <div className="form-group">
            <label htmlFor="name" className="label">Имя</label>
            <input 
              type="text" 
              id="name" 
              value={isEditing ? tempUserData.name : userData.name} 
              readOnly={!isEditing}
              onChange={handleChange}
            />
            {errors.name && <div className="error-message">{errors.name}</div>}
          </div>
          <div className="form-group">
            <label htmlFor="patronymic" className="label">Отчество</label>
            <input 
              type="text" 
              id="patronymic" 
              value={isEditing ? tempUserData.patronymic : userData.patronymic} 
              readOnly={!isEditing}
              onChange={handleChange}
            />
            {errors.patronymic && <div className="error-message">{errors.patronymic}</div>}
          </div>
        </div>
        <div className="form-row">
          <div className="form-group">
            <label htmlFor="phone" className="label">Телефон</label>
            <input 
              type="tel" 
              id="phone" 
              value={isEditing ? tempUserData.phone : formatPhoneNumber(userData.phone)} 
              readOnly={!isEditing}
              onChange={handleChange}
            />
            {errors.phone && <div className="error-message">{errors.phone}</div>}
          </div>
          <div className="form-group">
            <label htmlFor="email" className="label">Email</label>
            <input 
              type="email" 
              id="email" 
              value={isEditing ? tempUserData.email : userData.email} 
              readOnly={!isEditing}
              onChange={handleChange}
            />
            {errors.email && <div className="error-message">{errors.email}</div>}
          </div>
        </div>
        <div className="form-row">
          <div className="form-group">
            <label htmlFor="organization" className="label">Организация</label>
            <input 
              type="text" 
              id="organization" 
              value={isEditing ? tempUserData.organization : (userData.organization || '')} 
              readOnly={!isEditing}
              onChange={handleChange}
            />
            {errors.organization && <div className="error-message">{errors.organization}</div>}
          </div>
          <div className="form-group">
            <label htmlFor="inn" className="label">ИНН</label>
            <input 
              type="text" 
              id="inn" 
              value={isEditing ? tempUserData.inn : (userData.inn || '')} 
              readOnly={!isEditing}
              onChange={handleChange}
            />
            {errors.inn && <div className="error-message">{errors.inn}</div>}
          </div>
        </div>
      </form>
      {!isEditing ? (
        <button className="edit-button" onClick={handleEdit}>
          <span className="edit-button-text">Изменить</span>
        </button>
      ) : (
        <div className="button-group">
          <button className="save-button" onClick={handleSave}>
            <span className="button-text">Сохранить</span>
          </button>
          <button className="cancel-button" onClick={handleCancel}>
            <span className="button-text">Отмена</span>
          </button>
        </div>
      )}
    </div>
  );
};

export default UserInfo;