// src/components/KnowledgeBaseCreationPage.jsx
import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Sidebar from './Sidebar';
import WorkArea from './WorkArea';
import '../css/MainLayout.css';
import { Helmet } from 'react-helmet';

const KnowledgeBaseCreationPage = () => {
  const navigate = useNavigate();
  const { application_id } = useParams();

  const handleBack = () => {
    navigate(`/application/${application_id}`);
  };

  return (
    <div className="main-layout">
      <Helmet>
        <title>Создать базу знаний</title>
      </Helmet>
      <Sidebar 
        showBackButton={true}
        onBack={handleBack}
      />
      <WorkArea 
        activeContent="knowledge-base-creation"
      />
    </div>
  );
};

export default KnowledgeBaseCreationPage;