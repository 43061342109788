// src/components/ApplicationCreationPage.jsx
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import Sidebar from './Sidebar';
import WorkArea from './WorkArea';
import '../css/MainLayout.css';
import config from '../config';
import { Helmet } from 'react-helmet';

const ApplicationCreationPage = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const verifyToken = async () => {
      const accessToken = localStorage.getItem('access_token');
      if (!accessToken) {
        navigate('/auth');
        return;
      }

      try {
        // Здесь мы делаем запрос к API для проверки токена
        await axios.get(`${config.apiUrl}/users/me/`, {
          headers: {
            'Authorization': `Bearer ${accessToken}`
          }
        });
        setIsLoading(false);
      } catch (error) {
        console.error('Ошибка при проверке токена:', error);
        if (error.response && error.response.status === 401) {
          localStorage.removeItem('access_token');
          navigate('/auth');
        } else {
          // Обработка других ошибок
          setIsLoading(false);
        }
      }
    };

    verifyToken();
  }, [navigate]);

  const handleBack = () => {
    navigate('/account?mode=applications');
  };

  if (isLoading) {
    return <div>Загрузка...</div>;
  }

  return (
    <div className="main-layout">
      <Helmet>
        <title>Создать приложение</title>
      </Helmet>
      <Sidebar 
        showBackButton={true}
        onBack={handleBack}
      />
      <WorkArea 
        activeContent="application-creation"
      />
    </div>
  );
};

export default ApplicationCreationPage;