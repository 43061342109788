// src/components/Sidebar.jsx
import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import '../css/Sidebar.css';
import logo from '../static/img/logo.svg';

const Sidebar = ({ activeContent, setActiveContent, isApplicationPage, applicationName, onBack, showBackButton, knowledgeBaseName, isAuthPage }) => {
  const navigate = useNavigate();
  const location = useLocation(); // Получаем текущий путь

  const handleLogoClick = () => {
    navigate('/');
  };

  const handleSupportClick = () => {
    window.open('https://t.me/onlasbiz_bot', '_blank');
  };

  const handlePaymentClick = () => {
    // Здесь можно добавить логику для обработки нажатия на кнопку "Оплата"
    // Например, переход на страницу оплаты или открытие модального окна
    navigate('/payment'); // Пример перехода на страницу оплаты
  };

  return (
    <div className="sidebar">
      <div className="sidebar-content">
        <img src={logo} alt="Logo" className="logo" onClick={handleLogoClick} style={{ cursor: 'pointer' }} />

        {showBackButton && location.pathname === '/chat' ? (
          <button className="button back-button" onClick={onBack}>
            Назад
          </button>
        ) : null}

        {showBackButton && location.pathname !== '/auth' && location.pathname !== '/chat' ? (
          <>
            <button className="button back-button" onClick={onBack}>
              Назад
            </button>
            {knowledgeBaseName && (
              <button className="button knowledge-base-name active">
                {knowledgeBaseName}
              </button>
            )}
          </>
        ) : (
          <>
            {isApplicationPage ? (
              <>
                <button className="button back-button" onClick={onBack}>
                  Назад
                </button>
                <button className="button application-name active">
                  {applicationName}
                </button>
              </>
            ) : isAuthPage ? (
              <>
                <button 
                  className={`button ${activeContent === 'login' ? 'active' : ''}`} 
                  onClick={() => setActiveContent('login')}
                >
                  Вход
                </button>
                <button 
                  className={`button ${activeContent === 'register' ? 'active' : ''}`} 
                  onClick={() => setActiveContent('register')}
                >
                  Регистрация
                </button>
              </>
            ) : location.pathname === '/chat' ? (
              <>
                <button 
                  className={`button ${activeContent === 'chat' ? 'active' : ''}`} 
                  onClick={() => setActiveContent('chat')}
                >
                  Чат
                </button>
                <button 
                  className={`button ${activeContent === 'history' ? 'active' : ''}`} 
                  onClick={() => setActiveContent('history')}
                >
                  История
                </button>
              </>
            ) : (
              <>
                <button 
                  className={`button ${activeContent === 'userinfo' ? 'active' : ''}`} 
                  onClick={() => setActiveContent('userinfo')}
                >
                  Профиль
                </button>
                <button 
                  className={`button ${activeContent === 'applications' ? 'active' : ''}`} 
                  onClick={() => setActiveContent('applications')}
                >
                  Приложения
                </button>
              </>
            )}
          </>
        )}
          <button className="button payment-button" onClick={handlePaymentClick}>Оплата</button> {/* Новая кнопка "Оплата" */}
        
        <div className="bottom-buttons">

          <button className="support-button" onClick={handleSupportClick}>Поддержка</button>
          <button className="settings-button">⚙️</button>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;