// src/components/ChatHistory.jsx
import React from 'react';

const LawInfo = () => {
  return (
    <div>
      <h2>Оферта</h2>
      <p>
        Стоимость и информация о тарифах указана по адресу <a href="https://bointeg-test.ru/">https://bointeg-test.ru/</a> 
      </p>
      <h2>Условя возврата</h2>
      <p>Правила возврата товаров на нашем сайте:
        По <a href='http://www.consultant.ru/document/cons_doc_LAW_305/758e2cfdf136a621c8f66dcb3372b772c7b5e6e8/?' target='_blank'>закону «О защите прав потребителей»</a>  вы можете расторгнуть договор об оказании услуги в любое время. При этом часть услуг, которые уже были оказаны, нужно оплатить.
        Если вам не нравится качество обслуживания, мы бесплатно устраним недостатки или уменьшим цену услуги.
        О недостатках оказанной услуги можно сообщить в течение срока гарантии, а если он не установлен, то в течение двух лет.
        При оплате банковской картой деньги вернутся на ту карту, с которой был сделан платёж. Срок возврата — от 1 до 30 рабочих дней.
      </p>

      <h2>Реквизиты компании</h2>
      <p>
        Нвзвание: ООО "Бизнес-Онлайн",
        Адрес: 350042г. Краснодар, Улица им. 40-летия Победы, 20/1, 3 этаж, офис 301
        ИНН: 2308167221
        КПП: 230801001
        ОГРН: 1102308003984
      </p>

      <h2>Способы оплаты</h2>
      <p>
      Принимаем к оплате карты платёжных систем VISA, MasterCard, МИР.

Чтобы оплатить товар банковской картой, выберите этот способ оплаты при оформлении заказа. Далее перейдите на платёжную страницу банка и введите данные вашей банковской карты:

номер;
срок действия;
трёхзначный CVC2/CVV2 код (указан на оборотной стороне).
Если ваша карта подключена к услуге 3D-Secure, вы будете автоматически переадресованы на страницу банка, который выпустил карту, для аутентификации.

Интернет-платежи обрабатываются через безопасный шлюз банка согласно международному сертификату безопасности PCI DSS. Информация шифруется по технологии SSL и недоступна посторонним лицам».
      </p>
    </div>
  );
};

export default LawInfo;