// src/components/ChatPage.jsx
import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Sidebar from './Sidebar';
import WorkArea from './WorkArea';
import '../css/MainLayout.css';
import { Helmet } from 'react-helmet';

const ChatPage = () => {
  const navigate = useNavigate();
  const { applicationId, knowledgeBaseId } = useParams(); // Получаем applicationId из URL
  const [activeContent, setActiveContent] = useState('chat'); // По умолчанию активен чат

  const handleBack = () => {
    // Перенаправляем на предыдущую страницу
    navigate(`/application/${applicationId}`); // Перенаправляем на страницу приложений
  };

  return (
    <div className="main-layout">
       <Helmet>
        <title>БО Интегратор - Чат</title>
      </Helmet>
      <Sidebar 
        activeContent={activeContent}
        setActiveContent={setActiveContent}
        showBackButton={true} // Установите в true для страницы чата
        onBack={handleBack}
      />
      <WorkArea activeContent={activeContent} applicationId={applicationId} knowledgeBaseId={knowledgeBaseId}/> {/* Передаем applicationId в WorkArea */}
    </div>
  );
};

export default ChatPage;