import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import privacyPolicyPDF from '../static/docs/Политика конфиденциальности.pdf';


const PrivacyPolicy = () => {
    const navigate = useNavigate();

    useEffect(() => {
        // Задержка перед редиректом (например, 2 секунды)
        const timer = setTimeout(() => {
            navigate(privacyPolicyPDF); // Укажите путь к целевой странице
        });

        // Очистка таймера при размонтировании компонента
        return () => clearTimeout(timer);
    }, [navigate]);

    return (
        <div>
            <h1>Политика конфиденциальности</h1>
        </div>
    );
};

export default PrivacyPolicy;