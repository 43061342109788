// src/App.js
import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import AccountPage from './components/AccountPage';
import ApplicationPage from './components/ApplicationPage';
import KnowledgeBaseCreationPage from './components/KnowledgeBaseCreationPage';
import ApplicationCreationPage from './components/ApplicationCreationPage';
import KnowledgeBasePage from './components/KnowledgeBasePage';
import AuthPage from './components/AuthPage';
import ChatPage from './components/ChatPage'; // Импортируем ChatPage
import './css/App.css';
import MainPage from './components/MainPage';
import PaymentPage from './components/PaymentPage';
import LawInfo from './components/LawInfo'
import PrivacyPolicy from './components/PrivacyPolicy'
function App() {
  return (
    
    <Router>
      <div className="app">
        <Routes>
          <Route path="/" element={<MainPage />} />

          <Route path="/auth" element={<AuthPage />} />
          <Route path="/chat/:applicationId" element={<ChatPage />} /> {/* Обновленный маршрут для чата */}
          <Route path="/chat/:applicationId/:knowledgeBaseId" element={<ChatPage />} /> {/* Обновленный маршрут для чата */}
          <Route path="/account" element={<AccountPage />} />
          <Route path="/application/:application_id" element={<ApplicationPage />} />
          <Route path="/application/create" element={<ApplicationCreationPage />} />
          <Route path="/application/:application_id/knowledge-base/create" element={<KnowledgeBaseCreationPage />} />
          <Route path="/application/:application_id/knowledge-base/:knowledge_base_id" element={<KnowledgeBasePage />} />
      
          <Route path="/payment" element={<PaymentPage />} />
          <Route path="/lawinfo" element={<LawInfo />} />
          <Route path="/privacy" element={<PrivacyPolicy />} />
      
        </Routes>
      </div>
    </Router>
  );
}

export default App;