// src/components/KnowledgeBaseList.jsx
import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useParams, useLocation } from 'react-router-dom';
import axios from 'axios';
import '../css/KnowledgeBaseList.css';
import chatIcon from '../static/img/chat.svg';
import config from '../config';

const KnowledgeBaseList = () => {
  const [searchQuery, setSearchQuery] = useState('');
  const [knowledgeBaseItems, setKnowledgeBaseItems] = useState([]);
  const [applicationName, setApplicationName] = useState('');
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const location = useLocation(); // Получаем текущее местоположение
  const { application_id } = useParams();


  useEffect(() => {
    const fetchKnowledgeBases = async () => {
      const accessToken = localStorage.getItem('access_token');
      if (!accessToken) {
        console.error('Токен доступа отсутствует.');
        navigate('/auth');
        return;
      }

      if (!application_id) {
        console.error('ID приложения отсутствует в URL');
        setError('ID приложения отсутствует в URL');
        return;
      }

      try {
        const response = await axios.get(`${config.apiUrl}/${application_id}/knowledge_bases`, {
          headers: {
            'accept': 'application/json',
            'Authorization': `Bearer ${accessToken}`,
          },
        });

        console.log('Полученные данные:', response.data);
        setApplicationName(response.data.application_name);
        setKnowledgeBaseItems(response.data.knowledge_bases);
      } catch (err) {
        console.error('Ошибка при загрузке баз знаний:', err);
        if (err.response) {
          console.error('Ответ сервера:', err.response.data);
          console.error('Статус ответа:', err.response.status);
        } else if (err.request) {
          console.error('Запрос был отправлен, но ответ не получен');
        } else {
          console.error('Ошибка при настройке запроса:', err.message);
        }
        setError('Не удалось загрузить базы знаний. Пожалуйста, попробуйте позже.');
      }
    };

    fetchKnowledgeBases();
  }, [application_id, navigate]);

  const filteredAndSortedItems = knowledgeBaseItems
    .filter(item => item.name.toLowerCase().includes(searchQuery.toLowerCase()))
    .sort((a, b) => a.name.localeCompare(b.name));

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const handleAddClick = () => {
    navigate(`/application/${application_id}/knowledge-base/create`);
  };

  const handleChatClick = () => {
    navigate(`/chat/${application_id}`, { state: { from: location.pathname } });
  };

  if (error) {
    return <div className="error-message">{error}</div>;
  }

  return (
    <div className="knowledge-base-list">
      <div className="header">
        <h2>Базы знаний - {applicationName || 'Загрузка...'}</h2>
        <div className="chat-info" onClick={handleChatClick}>
          Чат приложения <img src={chatIcon} alt="Chat Icon" />
        </div>
      </div>
      <div className="controls">
        <button className="add-button" onClick={handleAddClick}>
          <span className="add-button-text">Добавить</span>
        </button>
        <input 
          type="text" 
          className="search-input" 
          placeholder="Поиск" 
          value={searchQuery}
          onChange={handleSearchChange}
        />
      </div>
      <div className="knowledge-base-grid">
        {filteredAndSortedItems.map((item) => (
          <Link
            key={item.id}
            to={`/application/${application_id}/knowledge-base/${item.id}`}
            className="knowledge-base-tile"
          >
            {item.name}
          </Link>
        ))}
      </div>
      {knowledgeBaseItems.length === 0 && !error && (
        <div className="no-items-message">Базы знаний не найдены</div>
      )}
    </div>
  );
};

export default KnowledgeBaseList;