// src/components/ApplicationPage.jsx
import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import Sidebar from './Sidebar';
import WorkArea from './WorkArea';
import '../css/MainLayout.css';
import config from '../config';
import { Helmet } from 'react-helmet';


const ApplicationPage = () => {
  const { application_id } = useParams(); // Получаем application_id из URL
  const navigate = useNavigate();
  const [applicationName, setApplicationName] = useState('');
  const [error, setError] = useState(null);

  useEffect(() => {
    const verifyTokenAndFetchData = async () => {
      const accessToken = localStorage.getItem('access_token');
      if (!accessToken) {
        navigate('/auth');
        return;
      }

      try {
        // Проверка токена
        await axios.get(`${config.apiUrl}/users/me/`, {
          headers: {
            'Authorization': `Bearer ${accessToken}`
          }
        });

        // Загрузка данных приложения
        const response = await axios.get(`${config.apiUrl}/application/${application_id}`, {
          headers: {
            'accept': 'application/json',
            'Authorization': `Bearer ${accessToken}`,
          },
        });

        setApplicationName(response.data.name);
      } catch (err) {
        console.error('Ошибка:', err);
        if (err.response && err.response.status === 401) {
          localStorage.removeItem('access_token');
          navigate('/auth');
        } else {
          setError('Не удалось загрузить данные. Пожалуйста, попробуйте позже.');
        }
      }
    };

    verifyTokenAndFetchData();
  }, [application_id, navigate]);

  const handleBack = () => {
    navigate('/account?mode=applications');
  };

  return (
    <div className="main-layout">
      <Helmet>
        <title>БО Интегратор - Приложение</title>
      </Helmet>
      <Sidebar 
        isApplicationPage={true}
        applicationName={applicationName || "Неизвестное приложение"}
        onBack={handleBack}
      />
      <WorkArea 
        activeContent="application" 
        applicationId={application_id} // Передаем applicationId в WorkArea
      />
      {error && <div className="error-message">{error}</div>}
    </div>
  );
};

export default ApplicationPage;