import React, { useState, useEffect } from 'react';
import axios from 'axios';
import '../css/ApplicationSecret.css';
import copyIcon from '../static/img/copy-icon.svg';
import regenerateIcon from '../static/img/regenerate-icon.svg';
import config from '../config';

const ApplicationSecret = ({ applicationId }) => {
  const [secret, setSecret] = useState('');
  const [isVisible, setIsVisible] = useState(false);
  const [isCopied, setIsCopied] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchSecret = async () => {
      const accessToken = localStorage.getItem('access_token');
      if (!accessToken) {
        console.error('Токен доступа отсутствует.');
        return;
      }

      try {
        const response = await axios.get(`${config.apiUrl}/application/${applicationId}`, {
          headers: {
            'accept': 'application/json',
            'Authorization': `Bearer ${accessToken}`,
          },
        });

        // Устанавливаем секретный ключ из ответа
        setSecret(response.data.boint_secret);
      } catch (err) {
        console.error('Ошибка при загрузке секретного ключа:', err.response ? err.response.data : err.message);
        setError('Не удалось загрузить секретный ключ. Пожалуйста, попробуйте позже.');
      }
    };

    fetchSecret();
  }, [applicationId]);

  const copyToClipboard = () => {
    navigator.clipboard.writeText(secret).then(() => {
      setIsCopied(true);
      setIsVisible(true);
      setTimeout(() => {
        setIsCopied(false);
      }, 2000);
    }).catch(err => {
      console.error('Ошибка при копировании: ', err);
    });
  };

  const regenerateSecret = async () => {
    const accessToken = localStorage.getItem('access_token');
    if (!accessToken) {
      console.error('Токен доступа отсутствует.');
      return;
    }

    try {
      const response = await axios.post(
        `${config.apiUrl}/reset_secret/${applicationId}`,
        {},
        {
          headers: {
            'accept': 'application/json',
            'Authorization': `Bearer ${accessToken}`,
          },
        }
      );

      // Обновляем секретный ключ из ответа
      setSecret(response.data.boint_secret);
      setIsVisible(false); // Скрываем секрет
    } catch (err) {
      console.error('Ошибка при обновлении секретного ключа:', err.response ? err.response.data : err.message);
      setError('Не удалось обновить секретный ключ. Пожалуйста, попробуйте позже.');
    }
  };

  return (
    <div className="application-secret-container">
      <div className="application-secret">
        <div className="header-app">
          <h4>Секретный ключ приложения</h4>
          <button className="regenerate-button" onClick={regenerateSecret}>
            <img src={regenerateIcon} alt="Генерировать заново" />
          </button>
        </div>
        <div className="secret-container">
          <div className={`secret-wrapper ${isVisible ? 'visible' : ''}`}>
            <span className="secret-text">{secret}</span>
            <span className="secret-mask">{'•'.repeat(secret.length)}</span>
          </div>
          <button 
            className={`copy-button ${isCopied ? 'copied' : ''}`} 
            onClick={copyToClipboard}
          >
            <img src={copyIcon} alt="Копировать" />
          </button>
        </div>
        {error && <div className="error-message">{error}</div>} {/* Показываем сообщение об ошибке, если есть */}
      </div>
    </div>
  );
};

export default ApplicationSecret;