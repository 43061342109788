import React, { useState } from 'react';
import axios from 'axios';
import '../css/Register.css'; // Подключите файл стилей
import { useNavigate } from 'react-router-dom'; // Импортируем useNavigate
import config from '../config';

const Register = () => {
  const navigate = useNavigate(); // Инициализация useNavigate
  const [formData, setFormData] = useState({
    name: '',
    lastname: '',
    patronymic: '',
    phone: '',
    email: '',
    password: '',
    confirmPassword: '',
    is_active: true,
    is_superuser: false,
  });

  const [errors, setErrors] = useState({});

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    setErrors({ ...errors, [name]: undefined }); // Сброс ошибок при изменении
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (formData.password !== formData.confirmPassword) {
      setErrors({ confirmPassword: 'Пароли не совпадают' });
      return;
    }

    // Подготовка данных для отправки
    const dataToSend = {
      name: formData.name,
      lastname: formData.lastname,
      patronymic: formData.patronymic,
      phone: formData.phone,
      email: formData.email,
      is_active: formData.is_active,
      is_superuser: formData.is_superuser,
      password: formData.password,
    };

    try {
      // Отправка данных на регистрацию
      await axios.post(`${config.apiUrl}/register`, dataToSend, {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        },
      });

      // Теперь автоматически авторизуем пользователя
      const loginResponse = await axios.post(`${config.apiUrl}/token`, {
        username: formData.email,
        password: formData.password,
      }, {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      });

      const accessToken = loginResponse.data.access_token;
      localStorage.setItem('access_token', accessToken); // Сохраняем токен

      console.log('Access Token:', accessToken);
      navigate('/account'); // Перенаправляем на страницу профиля
    } catch (error) {
      console.error('Ошибка при регистрации:', error);
      alert('Регистрация не удалась.');
    }
  };

  return (
    <div className="register">
      <h2>Регистрация</h2>
      <form onSubmit={handleSubmit} className="register-form">
        <div className="form-row">
          <div className="form-group">
            <label htmlFor="lastname" className="label">Фамилия</label>
            <input 
              type="text" 
              name="lastname" 
              value={formData.lastname} 
              onChange={handleChange} 
              required 
            />
            {errors.lastname && <div className="error-message">{errors.lastname}</div>}
          </div>
          <div className="form-group">
            <label htmlFor="name" className="label">Имя</label>
            <input 
              type="text" 
              name="name" 
              value={formData.name} 
              onChange={handleChange} 
              required 
            />
            {errors.name && <div className="error-message">{errors.name}</div>}
          </div>
          <div className="form-group">
            <label htmlFor="patronymic" className="label">Отчество</label>
            <input 
              type="text" 
              name="patronymic" 
              value={formData.patronymic} 
              onChange={handleChange} 
              required 
            />
            {errors.patronymic && <div className="error-message">{errors.patronymic}</div>}
          </div>
        </div>
        <div className="form-row">
          <div className="form-group">
            <label htmlFor="phone" className="label">Телефон</label>
            <input 
              type="tel" 
              name="phone" 
              value={formData.phone} 
              onChange={handleChange} 
              required 
            />
            {errors.phone && <div className="error-message">{errors.phone}</div>}
          </div>
          <div className="form-group">
            <label htmlFor="email" className="label">Email</label>
            <input 
              type="email" 
              name="email" 
              value={formData.email} 
              onChange={handleChange} 
              required 
            />
            {errors.email && <div className="error-message">{errors.email}</div>}
          </div>
        </div>
        <div className="form-row">
          <div className="form-group">
            <label htmlFor="password" className="label">Пароль</label>
            <input 
              type="password" 
              name="password" 
              value={formData.password} 
              onChange={handleChange} 
              required 
            />
            {errors.password && <div className="error-message">{errors.password}</div>}
          </div>
          <div className="form-group">
            <label htmlFor="confirmPassword" className="label">Повторите пароль</label>
            <input 
              type="password" 
              name="confirmPassword" 
              value={formData.confirmPassword} 
              onChange={handleChange} 
              required 
            />
            {errors.confirmPassword && <div className="error-message">{errors.confirmPassword}</div>}
          </div>
        </div>
        <button type="submit" className="register-button">Зарегистрироваться</button>
      </form>
    </div>
  );
};

export default Register;