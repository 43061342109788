import React, { useState, useEffect } from 'react';
import '../css/Rates.css'; // Подключение стилей
import config from '../config';
import Purchase from './Purchase';

const Rates = () => {
    const [rates, setRates] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [quantities, setQuantities] = useState({});

    useEffect(() => {
        const fetchRates = async () => {
            try {
                const response = await fetch(`${config.apiUrl}/rates`, {
                    method: 'GET',
                    headers: {
                        'Accept': 'application/json',
                    },
                });

                if (!response.ok) {
                    throw new Error('Ошибка при получении данных');
                }

                const data = await response.json();
                setRates(data);

                const initialQuantities = {};
                data.forEach(rate => {
                    initialQuantities[rate.id] = 0;
                });
                setQuantities(initialQuantities);
            } catch (err) {
                setError(err.message);
            } finally {
                setLoading(false);
            }
        };

        fetchRates();
    }, []);

    if (loading) {
        return <p>Загрузка...</p>;
    }

    if (error) {
        return <p>Ошибка: {error}</p>;
    }

    const handleIncrease = (rateId) => {
        setQuantities((prev) => ({ ...prev, [rateId]: prev[rateId] + 1 }));
    };

    const handleDecrease = (rateId) => {
        setQuantities((prev) => ({
            ...prev,
            [rateId]: Math.max(prev[rateId] - 1, 0),
        }));
    };

    const request = { 
        rates: Object.entries(quantities)
            .filter(([_, quantity]) => quantity > 0)
            .map(([rate_id, quantity]) => ({ rate_id: parseInt(rate_id), quantity })) 
    };

    return (
        <section className="rates" id='rates'>
            <h1 className='section-header'>Тарифы</h1>
            <div className="rates-container">
                <div className="rate-cards">
                    {rates.map((rate) => (
                        <div key={rate.id} className="rate-card">
                            <h3 className="rate-name">{rate.name}</h3>
                            <div className="rate-content">
                                <p><strong className="token-count">{rate.tokens.toLocaleString('ru-RU')}</strong> токенов</p>
                                <p>Описание: {rate.description}</p>
                                <p>Краткое описание: {rate.short_description}</p>
                                <div className="price-container">
                                    <p className="price">{rate.price.toLocaleString('ru-RU')}<span className='currency'>₽</span></p>
                                    {rate.discount > 0 && (
                                        <p className="discount">Скидка: {rate.discount}%</p>
                                    )}
                                    <div className="quantity-controls">
                                        {quantities[rate.id] === 0 ? (
                                            <button className="rate-card-button-text rate-button" onClick={() => handleIncrease(rate.id)}>Купить</button>
                                        ) : (
                                            <div className="quantity-wrapper"> {/* New wrapper for alignment */}
                                                <button className="quantity-button" onClick={() => handleDecrease(rate.id)}>-</button>
                                                <span className="quantity-display">{quantities[rate.id]}</span>
                                                <button className="quantity-button" onClick={() => handleIncrease(rate.id)}>+</button>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>

                {/* Move Purchase component here */}
                <Purchase request={request} />
            </div>
        </section>
    );
};

export default Rates;