import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import '../css/ApplicationCreation.css';
import config from '../config';

const ApplicationCreation = () => {
  const [applicationName, setApplicationName] = useState('');
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();

    const accessToken = localStorage.getItem('access_token');
    if (!accessToken) {
      navigate('/auth');
      return;
    }

    try {
      const response = await axios.post(
        `${config.apiUrl}/application`,
        { name: applicationName }, // Данные, которые вы отправляете
        {
          headers: {
            'accept': 'application/json',
            'Authorization': `Bearer ${accessToken}`, // Токен авторизации
            'Content-Type': 'application/json',
          },
        }
      );

      // Получаем данные созданного приложения из ответа
      const { id, boint_secret } = response.data;

      console.log('Создано новое приложение:', response.data);

      // Перенаправляем на страницу созданного приложения
      navigate(`/application/${id}`, { state: { bointSecret: boint_secret } });
    } catch (error) {
      console.error('Ошибка при создании приложения:', error.response ? error.response.data : error.message);
      alert('Произошла ошибка при создании приложения. Пожалуйста, попробуйте позже.');
    }
  };

  return (
    <div className="application-creation">
      <h2>Создание нового приложения</h2>
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label htmlFor="applicationName">Название приложения:</label>
          <input
            type="text"
            id="applicationName"
            value={applicationName}
            onChange={(e) => setApplicationName(e.target.value)}
            required
          />
        </div>
        <div className="button-container">
          <button type="submit" className="create-button">
            <span className="create-button-text">Создать</span>
          </button>
        </div>
      </form>
    </div>
  );
};

export default ApplicationCreation;