// src/components/AuthPage.jsx
import React, { useEffect, useState } from 'react';
import { useSearchParams, useNavigate, Outlet } from 'react-router-dom';
import Sidebar from './Sidebar';
import WorkArea from './WorkArea';
import '../css/MainLayout.css';
import { Helmet } from 'react-helmet';

const AuthPage = () => {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const mode = searchParams.get('mode') || 'login';
  
  // Устанавливаем состояние activeContent в зависимости от mode
  const [activeContent, setActiveContent] = useState(mode);

  useEffect(() => {
    // Если мы находимся на /auth без параметров, перенаправляем на /auth?mode=login
    if (!searchParams.has('mode')) {
      setSearchParams({ mode: 'login' });
    } else {
      // Устанавливаем activeContent в зависимости от mode
      setActiveContent(mode);
    }
  }, [searchParams, setSearchParams, mode]);

  const handleBack = () => {
    navigate('/');
  };

  return (
    <div className="main-layout">
       <Helmet>
        <title>БО Интегратор - Вход</title>
      </Helmet>
      <Sidebar 
        activeContent={activeContent}
        setActiveContent={setActiveContent}
        showBackButton={false} // Установите в false для страницы авторизации
        onBack={handleBack}
        isAuthPage={true} // Передаем флаг для отображения кнопок Вход и Регистрация
      />
      <WorkArea activeContent={activeContent} />
      <Outlet /> {/* Это позволит отображать вложенные маршруты, если это необходимо */}
    </div>
  );
};

export default AuthPage;