import React, { useState, useEffect, useRef, useCallback } from 'react';
import axios from 'axios';
import '../css/FileList.css';
import chatIcon from '../static/img/chat.svg';
import downloadIcon from '../static/img/download.svg';
import deleteIcon from '../static/img/delete.svg';
import config from '../config';
import { useNavigate } from 'react-router-dom';

const FileList = ({ applicationId, knowledgeBaseId, knowledgeBaseName }) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [files, setFiles] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const fileInputRef = useRef(null);

  const fetchFiles = useCallback(async () => {
    setIsLoading(true);
    const accessToken = localStorage.getItem('access_token');
    if (!accessToken) {
      console.error('Токен доступа отсутствует.');
      navigate('/auth');
      return;
    }
    try {
      const response = await axios.get(
        `${config.apiUrl}/knowledge_base/${knowledgeBaseId}/files`,
        {
          headers: {
            'Authorization': `Bearer ${accessToken}`
          }
        }
      );
      setFiles(response.data.files.map((file, index) => ({
        ...file,
        id: index,
        checked: false
      })));
    } catch (err) {
      setError('Failed to fetch files');
      console.error('Error fetching files:', err);
    } finally {
      setIsLoading(false);
    }
  }, [knowledgeBaseId, navigate]);

  useEffect(() => {
    fetchFiles();
  }, [fetchFiles]);

  const handleCheckboxChange = (id) => {
    setFiles(files.map(file => 
      file.id === id ? { ...file, checked: !file.checked } : file
    ));
  };

  const handleRowClick = (id) => {
    handleCheckboxChange(id);
  };

  const filteredFiles = files.filter(file => 
    file.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const handleDownload = async () => {
    const accessToken = localStorage.getItem('access_token');
    if (!accessToken) {
      console.error('Токен доступа отсутствует.');
      navigate('/auth');
      return;
    }

    const filesToDownload = files.filter(file => file.checked).map(file => file.name);
    
    if (filesToDownload.length === 0) {
      console.log("Нет выбранных файлов для скачивания");
      return;
    }

    try {
      const queryParams = filesToDownload.map(fileName => `file_names=${encodeURIComponent(fileName)}`).join('&');
      const response = await axios.get(
        `${config.apiUrl}/${knowledgeBaseId}/file/download_files?${queryParams}`,
        {
          headers: {
            'Authorization': `Bearer ${accessToken}`
          },
          responseType: 'blob' // Важно для корректной обработки бинарных данных
        }
      );

      // Создаем ссылку для скачивания
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', filesToDownload.length > 1 ? `${knowledgeBaseName}.zip` : filesToDownload[0]);
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
    } catch (error) {
      console.error('Error downloading files:', error);
      setError('Failed to download files');
    }
  };

  const handleDelete = async () => {
    const accessToken = localStorage.getItem('access_token');
    if (!accessToken) {
      console.error('Токен доступа отсутствует.');
      navigate('/auth');
      return;
    }

    const filesToDelete = files.filter(file => file.checked).map(file => file.name);
    
    if (filesToDelete.length === 0) {
      console.log("Нет выбранных файлов для удаления");
      return;
    }

    try {
      const queryParams = filesToDelete.map(fileName => `file_names=${encodeURIComponent(fileName)}`).join('&');
      await axios.delete(
        `${config.apiUrl}/${knowledgeBaseId}/file/?${queryParams}`,
        {
          headers: {
            'Authorization': `Bearer ${accessToken}`
          }
        }
      );
      console.log("Файлы успешно удалены");
      await fetchFiles(); // Обновляем список файлов после удаления
    } catch (error) {
      console.error('Error deleting files:', error);
      setError('Failed to delete files');
    }
  };

  const handleFileUpload = async (event) => {
    const file = event.target.files[0];
    if (!file) return;

    const accessToken = localStorage.getItem('access_token');
    if (!accessToken) {
      console.error('Токен доступа отсутствует.');
      navigate('/auth');
      return;
    }

    const formData = new FormData();
    formData.append('file', file);

    try {
      await axios.post(
        `${config.apiUrl}/${knowledgeBaseId}/file/upload_file`,
        formData,
        {
          headers: {
            'Authorization': `Bearer ${accessToken}`,
            'Content-Type': 'multipart/form-data'
          }
        }
      );
      // После успешной загрузки обновляем список файлов
      await fetchFiles();
    } catch (error) {
      console.error('Error uploading file:', error);
      setError('Failed to upload file');
    }
  };

  const handleAddButtonClick = () => {
    fileInputRef.current.click();
  };

  const isAnyFileChecked = files.some(file => file.checked);

  if (isLoading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;
  const handleChatClick = () => {
    navigate(`/chat/${applicationId}/${knowledgeBaseId}`);
  };

  return (
    <div className="file-list">
      <div className="header">
        <h2>Файлы</h2>
        <div className="chat-info" onClick={handleChatClick}>
          Чат базы знаний <img src={chatIcon} alt="Chat Icon" />
        </div>
      </div>
      <div className="controls">
        <button className="add-button" onClick={handleAddButtonClick}>
          <span className="add-button-text">Добавить</span>
        </button>
        <input 
          type="file"
          ref={fileInputRef}
          style={{ display: 'none' }}
          onChange={handleFileUpload}
        />
        <input 
          type="text" 
          className="search-input" 
          placeholder="Поиск" 
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
      </div>
      <div className="action-buttons">
        <button className="icon-button" onClick={handleDownload} disabled={!isAnyFileChecked}>
          <img src={downloadIcon} alt="Download" />
        </button>
        <button className="icon-button" onClick={handleDelete} disabled={!isAnyFileChecked}>
          <img src={deleteIcon} alt="Delete" />
        </button>
      </div>
      
      <table>
        <thead>
          <tr>
            <th></th>
            <th>Имя файла</th>
            <th>Размер</th>
            <th>Дата загрузки</th>
          </tr>
        </thead>
        <tbody>
          {filteredFiles.map((file) => (
            <tr key={file.id} onClick={() => handleRowClick(file.id)}>
              <td>
                <input 
                  type="checkbox" 
                  checked={file.checked}
                  onChange={() => handleCheckboxChange(file.id)}
                />
              </td>
              <td title={file.name}>{file.name}</td>
              <td>{file.size}</td>
              <td>{file.updated_at}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default FileList;