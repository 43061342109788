// src/components/WorkArea.jsx
import React from 'react';
import InfoBar from './InfoBar';
import ApplicationList from './ApplicationList';
import UserInfo from './UserInfo';
import ApplicationSecret from './ApplicationSecret';
import KnowledgeBaseList from './KnowledgeBaseList';
import ApplicationCreation from './ApplicationCreation';
import KnowledgeBaseCreation from './KnowledgeBaseCreation';
import FileList from './FileList';
import Login from './Login';
import Register from './Register';
import Chat from './Chat'; // Импортируем компонент Chat
import ChatHistory from './ChatHistory'; // Импортируем компонент ChatHistory
import Payment from './Payment';
import '../css/WorkArea.css';

const WorkArea = ({ activeContent, applicationId, knowledgeBaseId, knowledgeBaseName }) => {
  return (
    <div className="work-area">
      <InfoBar />
      {activeContent === 'applications' && <ApplicationList />}
      {activeContent === 'userinfo' && <UserInfo />}
      {activeContent === 'application' && (
        <div className="application-content">
          <ApplicationSecret applicationId={applicationId} />
          <KnowledgeBaseList applicationId={applicationId} />
        </div>
      )}
      {activeContent === 'application-creation' && <ApplicationCreation />}
      {activeContent === 'knowledge-base-creation' && <KnowledgeBaseCreation />}
      {activeContent === 'file-list' && <FileList knowledgeBaseId={knowledgeBaseId} applicationId={applicationId} knowledgeBaseName={knowledgeBaseName}/>}
      {activeContent === 'login' && <Login />}
      {activeContent === 'register' && <Register />}
      {activeContent === 'chat' && <Chat applicationId={applicationId} knowledgeBaseId={knowledgeBaseId}/>} {/* Передаем applicationId в компонент Chat */}
      {activeContent === 'history' && <ChatHistory />} {/* Отображаем компонент ChatHistory */}
      {activeContent === 'payment' && <Payment />} {/* Отображаем компонент ChatHistory */}
      </div>
  );
};

export default WorkArea;