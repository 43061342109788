import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import '../css/KnowledgeBaseCreation.css';
import config from '../config';

const KnowledgeBaseCreation = () => {
  const [knowledgeBaseName, setKnowledgeBaseName] = useState('');
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const { application_id } = useParams();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(null);

    const accessToken = localStorage.getItem('access_token');
    if (!accessToken) {
      setError('Токен доступа отсутствует. Пожалуйста, войдите в систему.');
      return;
    }

    try {
      const response = await axios.post(
        `${config.apiUrl}/${application_id}/knowledge_base`,
        { name: knowledgeBaseName },
        {
          headers: {
            'accept': 'application/json',
            'Authorization': `Bearer ${accessToken}`,
            'Content-Type': 'application/json',
          },
        }
      );

      console.log('Создана новая база знаний:', response.data);
      // После успешного создания перенаправляем на страницу базы знаний
      navigate(`/application/${application_id}/knowledge-base/${response.data.id}`);
    } catch (err) {
      console.error('Ошибка при создании базы знаний:', err);
      setError(err.response?.data?.detail || 'Произошла ошибка при создании базы знаний');
    }
  };

  return (
    <div className="knowledge-base-creation">
      <h2>Создание новой базы знаний</h2>
      {error && <div className="error-message">{error}</div>}
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label htmlFor="knowledgeBaseName">Название базы знаний:</label>
          <input
            type="text"
            id="knowledgeBaseName"
            value={knowledgeBaseName}
            onChange={(e) => setKnowledgeBaseName(e.target.value)}
            required
          />
        </div>
        <div className="button-container">
          <button type="submit" className="create-button">
            <span className="create-button-text">Создать</span>
          </button>
        </div>
      </form>
    </div>
  );
};

export default KnowledgeBaseCreation;