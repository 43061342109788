import React from 'react';
import { useForm } from 'react-hook-form';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import '../css/Login.css'; // Подключите файл стилей
import config from '../config';

const Login = () => {
  const { register, handleSubmit, formState: { errors } } = useForm();
  const navigate = useNavigate();

  const onSubmit = async (data) => {
    try {
      const response = await axios.post(`${config.apiUrl}/token`, {
        username: data.email,
        password: data.password,
      }, {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      });

      const accessToken = response.data.access_token;
      localStorage.setItem('access_token', accessToken);

      console.log('Access Token:', response.data.access_token);
      navigate('/account');
    } catch (error) {
      console.error('Error logging in:', error.response?.data?.detail || error.message);
      alert('Incorrect email or password');
    }
  };

  return (
    <div className="login">
      <h2>Вход</h2>
      <form onSubmit={handleSubmit(onSubmit)} className="login-form">
        <div className="form-row">
          <div className="form-group">
            <label htmlFor="email" className="label">Email</label>
            <input
              type="text"
              id="email"
              {...register('email', { required: 'email is required' })}
            />
            {errors.email && <div className="error-message">{errors.email.message}</div>}
          </div>
        </div>
        <div className="form-row">
          <div className="form-group">
            <label htmlFor="password" className="label">Пароль</label>
            <input
              type="password"
              id="password"
              {...register('password', { required: 'Password is required' })}
            />
            {errors.password && <div className="error-message">{errors.password.message}</div>}
          </div>
        </div>
        <button type="submit" className="login-button">Войти</button>
      </form>
    </div>
  );
};

export default Login;