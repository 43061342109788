// src/components/AccountPage.jsx
import React, { useEffect } from 'react';
import { useSearchParams, Outlet, useNavigate } from 'react-router-dom';
import axios from 'axios';
import Sidebar from './Sidebar';
import WorkArea from './WorkArea';
import '../css/MainLayout.css';
import config from '../config';
import { Helmet } from 'react-helmet';

const AccountPage = () => {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    const verifyToken = async () => {
      const accessToken = localStorage.getItem('access_token');
      if (!accessToken) {
        navigate('/auth');
        return;
      }

      try {
        // Здесь мы делаем запрос к API для проверки токена
        await axios.get(`${config.apiUrl}/users/me/`, {
          headers: {
            'Authorization': `Bearer ${accessToken}`
          }
        });
      } catch (error) {
        console.error('Ошибка при проверке токена:', error);
        if (error.response && error.response.status === 401) {
          localStorage.removeItem('access_token');
          navigate('/auth');
        }
      }
    };

    verifyToken();
  }, [navigate]);

  const activeContent = searchParams.get('mode') || 'applications';

  const setActiveContent = (content) => {
    setSearchParams({ mode: content });
  };

  return (
    <div className="main-layout">
      <Helmet>
        <title>БО Интегратор - Ваш ассистент</title>
      </Helmet>
      <Sidebar activeContent={activeContent} setActiveContent={setActiveContent} />
      <WorkArea activeContent={activeContent} />
      <Outlet /> {/* Это позволит отображать вложенные маршруты */}
    </div>
  );
};

export default AccountPage;