// src/components/InfoBar.jsx
import React, { useEffect, useState } from 'react';
// import { useNavigate, useLocation } from 'react-router-dom'; // Импортируем useLocation
import '../css/InfoBar.css';
// import chatIcon from '../static/img/chat.svg';
import config from '../config';

// Функция для форматирования числа
const formatNumber = (num) => {
  if (num < 1000000000000) { // Меньше триллиона
    return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
  } else { // Триллион и больше
    return (num / 1000000000000).toFixed(1) + ' T';
  }
};

// Функция для получения правильного склонения слова "токен"
const getTokenWord = (balance) => {
  const lastDigit = balance % 10;
  const lastTwoDigits = balance % 100;

  if (lastTwoDigits >= 11 && lastTwoDigits <= 14) {
    return 'токенов';
  }

  switch (lastDigit) {
    case 1:
      return 'токен';
    case 2:
    case 3:
    case 4:
      return 'токена';
    default:
      return 'токенов';
  }
};

const InfoBar = ({ applicationId }) => {


  const [balance, setBalance] = useState(0);
  const [error, setError] = useState(null);
  // const navigate = useNavigate(); 
  // const location = useLocation(); // Получаем текущее местоположение

  useEffect(() => {
    const fetchBalance = async () => {
      try {
        const token = localStorage.getItem('access_token'); // Получаем токен из localStorage
        const response = await fetch(`${config.apiUrl}/balance`, {
          method: 'GET',
          headers: {
            'Accept': 'application/json',
            'Authorization': `Bearer ${token}`,
          },
        });

        if (!response.ok) {
          throw new Error('Ошибка при получении баланса');
        }

        const data = await response.json();
        setBalance(data.tokens); // Устанавливаем полученное значение баланса
      } catch (err) {
        setError(err.message);
      } 
    };

    fetchBalance();
  }, []);
  // const handleChatClick = () => {
  //   navigate(`/chat/${applicationId}`, { state: { from: location.pathname, applicationId } }); // Перенаправляем на страницу чата с applicationId в URL

  // };

  if (error) {
    return;
  }

  const formattedBalance = formatNumber(balance);
  const tokenWord = getTokenWord(balance);

  return (
    <div className="info-bar">
      <div className="balance-container">
        <div className="balance-value-container">
        <span className="balance-label">Баланс:</span>

          <span className="balance-value">{formattedBalance}</span>
          <span className="token-word">{tokenWord}</span>
        </div>
      </div>
      {/*<div className="chat-info" style={{ cursor: 'pointer' }}> 
        Чат <img src={chatIcon} alt="Chat Icon" />
      </div>*/}
    </div>
  );
};

export default InfoBar;