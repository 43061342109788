// src/components/Chat.jsx
import React, { useState, useEffect, useRef, useCallback } from 'react';
import axios from 'axios';
import '../css/Chat.css'; // Импортируйте свой CSS для стилей
import sendIcon from '../static/img/send.svg'; // Импортируйте иконку отправки
import config from '../config'; // Импортируйте конфигурацию для API

const Chat = ({ applicationId, knowledgeBaseId }) => {
  const [messages, setMessages] = useState([]); // Хранение сообщений
  const [inputValue, setInputValue] = useState(''); // Значение строки ввода
  const messageEndRef = useRef(null); // Реф для последнего сообщения

  const fetchMessages = useCallback(async () => {
    const accessToken = localStorage.getItem('access_token');
    try {
      const messagesResponse = await axios.get(
        `${config.apiUrl}/messages/${applicationId}/0`,
        {
          headers: {
            'accept': 'application/json',
            'Authorization': `Bearer ${accessToken}`,
          },
        }
      );

      // Обновляем состояние с новыми сообщениями
      const newMessages = messagesResponse.data.map((msg) => ({
        type: msg.type === 'human' ? 'user' : 'bot',
        text: msg.content,
      }));
      
      setMessages(newMessages); // Обновляем состояние с новыми сообщениями
    } catch (error) {
      console.error('Ошибка при получении сообщений:', error);
      const errorMessage = { type: 'bot', text: 'Ошибка при получении сообщений от сервера.' };
      setMessages((prevMessages) => [...prevMessages, errorMessage]);
    }
  }, []); // Здесь передаем пустой массив зависимостей

  const handleSend = async () => {
    if (inputValue.trim() === '') return; // Не отправлять пустые сообщения

    // Добавляем сообщение пользователя
    const userMessage = { type: 'user', text: inputValue };
    setMessages((prevMessages) => [...prevMessages, userMessage]);

    // Определяем URL для запроса в зависимости от knowledgeBaseId
    const accessToken = localStorage.getItem('access_token');
    const url = knowledgeBaseId != null 
      ? `${config.apiUrl}/message-to-rag/${knowledgeBaseId}` 
      : `${config.apiUrl}/message/${applicationId}`;

    try {
      await axios.post(
        url,
        {
          user_message: inputValue,
          task_id: 0,
        },
        {
          headers: {
            'accept': 'application/json',
            'Authorization': `Bearer ${accessToken}`,
            'Content-Type': 'application/json',
          },
        }
      );

      // Выполняем запрос к API для получения новых сообщений
      await fetchMessages();
      
    } catch (error) {
      console.error('Ошибка при отправке сообщения:', error);
      const errorMessage = { type: 'bot', text: 'Ошибка при получении ответа от сервера.' };
      setMessages((prevMessages) => [...prevMessages, errorMessage]);
    }

    // Очищаем строку ввода
    setInputValue('');
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault(); // Предотвращаем переход на новую строку
      handleSend(); // Вызываем функцию отправки сообщения
    }
  };

  const scrollToBottom = () => {
    messageEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  useEffect(() => {
    fetchMessages(); // Выполняем запрос при загрузке компонента
  }, [fetchMessages]); // Добавляем fetchMessages в зависимости

  useEffect(() => {
    scrollToBottom(); // Прокручиваем вниз только после загрузки компонента и получения новых сообщений
  }, [messages]); // Прокручиваем вниз только при изменении списка сообщений

  return (
    <div className="chat-container">
      <div className="message-list">
        {messages.map((message, index) => (
          <div
            key={index}
            className={`message ${message.type === 'user' ? 'user-message' : 'bot-message'}`}
          >
            {message.text}
          </div>
        ))}
        <div ref={messageEndRef} /> {/* Элемент для прокрутки */}
      </div>
      <div className="input-container">
        <input
          type="text"
          value={inputValue}
          onChange={(e) => setInputValue(e.target.value)}
          onKeyDown={handleKeyDown} // Добавляем обработчик нажатия клавиш
          placeholder="Введите ваше сообщение..."
          className='chat-input'
        />
        <button onClick={handleSend} className="send-button">
          <img src={sendIcon} alt="Send" />
        </button>
      </div>
    </div>
  );
};

export default Chat;