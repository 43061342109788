// src/components/ChatHistory.jsx
import React from 'react';
import Rates from './Rates';


const Payment = () => {
  return (
    <div >
      <Rates />

    </div>
  );
};

export default Payment;