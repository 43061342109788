import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import '../css/ApplicationList.css';
import config from '../config';

const ApplicationList = () => {
  const [searchQuery, setSearchQuery] = useState('');
  const [applications, setApplications] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchApplications = async () => {
      try {
        const accessToken = localStorage.getItem('access_token');
        
        console.log(config.apiUrl)

        const response = await axios.get(`${config.apiUrl}/application`, {
          headers: {
            'accept': 'application/json',
            'Authorization': `Bearer ${accessToken}`,
          },
        });

        setApplications(response.data);
      } catch (error) {
        console.error('Error fetching applications:', error);
      }
    };

    fetchApplications();
  }, [navigate]);

  const filteredAndSortedApplications = applications
    .filter(app => app.name.toLowerCase().includes(searchQuery.toLowerCase()))
    .sort((a, b) => a.name.localeCompare(b.name));

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const handleAddClick = () => {
    navigate('/application/create');
  };

  return (
    <div className="application-list">
      <div className="controls">
        <button className="add-button" onClick={handleAddClick}>
          <span className="add-button-text">Добавить</span>
        </button>
        <input 
          type="text" 
          className="search-input" 
          placeholder="Поиск" 
          value={searchQuery}
          onChange={handleSearchChange}
        />
      </div>
      <div className="applications-grid">
        {filteredAndSortedApplications.map((app) => (
          <Link
            key={app.id}
            to={`/application/${app.id}`}
            className="application-tile"
          >
            {app.name}
          </Link>
        ))}
      </div>
    </div>
  );
};

export default ApplicationList;